import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Media, MediaService } from '@igo2/core';
import {
  ClusterDataSource,
  computeOlFeaturesExtent,
  DataSourceService,
  FEATURE,
  Feature,
  FeatureDataSource,
  FeatureMotion,
  FeatureStore,
  FeatureStoreSelectionStrategy,
  featureToOl,
  IgoMap,
  LayerService,
  MapViewOptions,
  QueryableDataSourceOptions,
  StyleService,
  VectorLayer,
} from '@igo2/geo';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { GoogleAnalyticsService } from '../../google-analytics.service';
import {
  baselayersAnimation,
  controlsAnimations,
  controlSlideX,
  controlSlideY,
  expansionPanelAnimation,
  mapSlideX,
  mapSlideY,
  toastPanelAnimation,
} from './portal.animation';
import { Celebrity, OpenSidePanelState } from './portal.interface';
import { PortalService } from './portal.service';

@Component({
  selector: 'app-tm-portal',
  templateUrl: './tm-portal.component.html',
  styleUrls: ['./tm-portal.component.scss'],
  animations: [
    expansionPanelAnimation(),
    toastPanelAnimation(),
    baselayersAnimation(),
    controlsAnimations(),
    controlSlideX(),
    controlSlideY(),
    mapSlideX(),
    mapSlideY()
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmPortalComponent implements OnInit, OnDestroy {
  public sidenavOpened: boolean = true;

  public getOpenSidePanelState$: Observable<OpenSidePanelState> = this.portalService.getOpenSidePanelState();

  public map = new IgoMap({
    overlay: true,
    controls: {
      attribution: {
        collapsed: true
      }
    }
  });

  public view: MapViewOptions = {
    center: [-69, 50.2],
    zoom: 6,
    maxZoomOnExtent: 17,
    maxZoom: 17,
  };

  public store = new FeatureStore([], { map: this.map });

  public currentSelectedPlaceId: number;

  public currentSelected: Celebrity = null;

  private selectedEntitiesList$$: Subscription;
  private selectedCelebrity$$: Subscription;
  private selectedPlaceId$$: Subscription;
  private openSidePanelState$$: Subscription;
  private getAllLayers$$: Subscription;
  private getLayerVisibility$$: Subscription;

  private currentOpenSidePanelState: OpenSidePanelState = OpenSidePanelState.Full;
  private selectionStrategy: FeatureStoreSelectionStrategy;

  @ViewChild('mapBrowser', { read: ElementRef, static: true })
  mapBrowser: ElementRef;

  constructor(
    private dataSourceService: DataSourceService,
    private layerService: LayerService,
    private portalService: PortalService,
    private mediaService: MediaService,
    private styleService: StyleService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) { }

  private setMapPosition():void {
    console.log(this.mediaService.getMedia());

    if (this.isMobile() && window.innerHeight < 740) {
      console.log('here: ', window.innerHeight)
      this.view = {
        center: [-72, 50],
        zoom: 5,
        maxZoomOnExtent: 17
      }
    } else if (this.isMobile()) {
      console.log('move map to mobile position');
      this.view = {
        center: [-72, 52],
        zoom: 5,
        maxZoomOnExtent: 17
      }
    } else if (this.isTablet() || window.innerHeight <= 770) {
      console.log('move map to tablet || 768 position');
      this.view = {
        center: [-70, 48],
        zoom: 6,
        maxZoomOnExtent: 17
      };
    } else if (window.innerHeight <= 900) {
      console.log('move map top 900 position');
      this.view = {
        center: [-70, 49],
        zoom: 6,
        maxZoomOnExtent: 17
      };

    }
  }

  public ngOnInit(): void {

    if (this.isMobile()) {
      this.portalService.setOpenSidePanelState(OpenSidePanelState.Mid);
    }

    this.setMapPosition();

    const selectedStyle = this.styleService.createStyle({
      style: {
        circle: {
          radius: 0,
          fill: {
            color: null
          }
        }
      }
    });

    this.selectionStrategy = new FeatureStoreSelectionStrategy({
      map: this.map,
      motion: FeatureMotion.Default,
      dragBox: false,
      many: false,
      layer: new VectorLayer({
        zIndex: 300,
        source: new FeatureDataSource(),
        style: selectedStyle,
        showInLayerList: false,
        exportable: false,
        browsable: false
      })
    });
    this.store.addStrategy(this.selectionStrategy);

    this.selectedEntitiesList$$ = this.portalService.getSelectedEntitiesList()
      .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
      .subscribe(entitiesList => {
        console.log('received: ', entitiesList);
        if (entitiesList && entitiesList.length) {
          this.map.setView(this.view);
          this.store.load(entitiesList);
          this.updateClusteredMarkers();
        }
      })

    // default map loading
    this.dataSourceService
      .createAsyncDataSource({
        url: "https://geoegl.msp.gouv.qc.ca/carto/tms/1.0.0/carte_gouv_qc_public@EPSG_3857/{z}/{x}/{-y}.png",
        attributions: `© <a href='http://www.droitauteur.gouv.qc.ca/copyright.php' target='_blank'><img src='' width='64' height='14'>Gouvernement du Québec</a> / <a href='http://www.igouverte.org/' target='_blank'>IGO2</a>`,
        type: 'xyz',
        maxZoom: 17,
      } as any)
      .subscribe(dataSource => {
        this.layerService.createAsyncLayer({
          title: 'Carte Topo',
          source: dataSource,
          baseLayer: true,
          visible: true,
          
        }).subscribe(layer => this.map.addLayer(layer));
      });

      this.dataSourceService
      .createAsyncDataSource({
        url: 'https://geoegl.msp.gouv.qc.ca/carto/tms/1.0.0/orthos@EPSG_3857/{z}/{x}/{-y}.jpeg',
        attributions: `© <a href='http://www.droitauteur.gouv.qc.ca/copyright.php' target='_blank'><img src='' width='64' height='14'>Gouvernement du Québec</a> / <a href='http://www.igouverte.org/' target='_blank'>IGO2</a>`,
        type: 'xyz',
        maxZoom: 17,
      } as any)
      .subscribe(dataSource => {
        this.layerService.createAsyncLayer({
          title: 'Imagerie',
          source: dataSource,
          baseLayer: true,
        }).subscribe(layer => this.map.addLayer(layer));
      });

    // display commemoratives places from celected celebrity on map
    this.selectedCelebrity$$ = this.portalService.getSelectedCelebrity()
      .subscribe(selectedCelebrity => {
        this.currentSelected = selectedCelebrity;
        console.log('here');
        if (selectedCelebrity) {
          this.portalService.filterSelectedEntititesList(selectedCelebrity.commemorativePlacesIdsList);
        } else {
          this.portalService.filterSelectedEntititesList([]);
        }
      })

    this.selectedPlaceId$$ = this.portalService.getSelectedPlaceId()
      .pipe(distinctUntilChanged())
      .subscribe(placeId => {
        console.log('selected from sidepanel: ', placeId);
        this.updateSelectedState(placeId);
      })

    this.openSidePanelState$$ = this.getOpenSidePanelState$
      .subscribe(openSidePanelState => {
        console.log('[sidenav observable] ', openSidePanelState);
        this.currentOpenSidePanelState = openSidePanelState
      });

      //get and set the value of the current visible layer
      this.getAllLayers$$ = this.map.layers$.subscribe(arrayLayers => {
        arrayLayers.forEach(layer => this.getLayerVisibility$$ = layer.visible$.subscribe(isVisible => {
          if (isVisible) {
            this.portalService.setCurrentVisibleLayer(layer.options.title);
          }
        }
        ));
      });
  }

  public ngOnDestroy(): void {
    this.store.destroy();
    this.selectedEntitiesList$$.unsubscribe();
    this.selectedCelebrity$$.unsubscribe();
    this.selectedPlaceId$$.unsubscribe();
    this.openSidePanelState$$.unsubscribe();
    this.getAllLayers$$.unsubscribe();
    this.getLayerVisibility$$.unsubscribe();
    this.portalService.resetServiceValues();
  }

  public isMobile(): boolean {
    return this.mediaService.getMedia() === Media.Mobile;
  }

  public isTablet(): boolean {
    return this.mediaService.getMedia() === Media.Tablet;
  }

  public OpenOrCloseSidePanelState(): void {
    // console.log('change panel state');
    if (this.sidenavOpened) {
      this.portalService.setOpenSidePanelState(OpenSidePanelState.Close);
    } else {
      if (this.isMobile()) {
        this.portalService.setOpenSidePanelState(OpenSidePanelState.Mid);
      } else {
        this.portalService.setOpenSidePanelState(OpenSidePanelState.Full);
      }
    }
    this.sidenavOpened = !this.sidenavOpened;
  }

  public updateMapBrowserClass(): void {
    this.removeCSSClasses();
    if (this.sidenavOpened) {
      if (this.isMobile()) {
        if (this.currentOpenSidePanelState === 'min') {
          console.log('[ mobile ] sidenav open to min');
          this.mapBrowser.nativeElement.classList.add('mobile-min-sidenav-offset');
        } else if (this.currentOpenSidePanelState === 'mid') {
          console.log('[ mobile ] sidenav open to mid');
          this.mapBrowser.nativeElement.classList.add('mobile-mid-sidenav-offset');
        }
      } else {
        console.log('[desktop or tablet] sidenav open');
        this.mapBrowser.nativeElement.classList.add('desktop-sidenav-offset');
      }
    }

    this.mapBrowser.nativeElement.dispatchEvent(new Event('resize', { bubbles: true }));
  }

  public handleQueryResults(event: any): void {
    console.log('click on ', event);
    if (event.features.length === 1) {
      const featureId = event.features[0].meta.id;
      const placeId = parseInt(featureId.slice(3, featureId.length));
      this.updateSelectedState(placeId);

      const currentEntity = this.store.entities$.value.find(entity => entity.meta.id === placeId);
      this.googleAnalyticsService.eventEmitter('selection_lieu', 'clic_sur_carte', currentEntity.properties.name);

    } else if (event.features.length > 1) {
      const featuresOlList = event.features
        .map(feature => featureToOl(feature as any, this.map.projection));
      this.map.viewController.zoomToExtent(computeOlFeaturesExtent(this.map, featuresOlList));
    }
  }

  public changeOpenSidePanelStateToMin(): void {
    console.log('open sidePanel to min');
    this.portalService.setOpenSidePanelState(OpenSidePanelState.Min);
    this.portalService.setSelectedPlaceId(null);
  }

  private displaySelectedOnMap(entity: any): void {
    this.map.overlay.clear();
    const selected: Feature = {
      type: FEATURE,
      projection: 'EPSG:4326',
      meta: {
        id: 1,
        style: {
          icon: {
            src: 'assets/icons/map-pin-selected.svg',
            scale: 1.5,
          },
          zIndex: 2000
        },
      },
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: entity.geometry.coordinates
      }
    };

    console.log('zoom on ', selected, ' to ',  this.map.getZoom());

    this.map.overlay.setFeatures(
      [selected],
      FeatureMotion.Zoom
    );
  }

  private removeCSSClasses(): void {
    this.mapBrowser.nativeElement.classList.remove('desktop-sidenav-offset');
    this.mapBrowser.nativeElement.classList.remove('mobile-min-sidenav-offset');
    this.mapBrowser.nativeElement.classList.remove('mobile-mid-sidenav-offset');
  }

  private updateClusteredMarkers(): void {

    const baseStyle = {
      icon: {
        src: 'assets/icons/map-pin.svg',
        scale: 1.5
      }
    };

    const clusterParam = {
      clusterRanges: [
        {
          showRange: true,
          dynamicRadius: true,
          style: {
            circle: {
              opacity: 1,
              radius: 18,
              stroke: {
                color: 'white',
                width: 4
              },
              fill: {
                color: '#23847e',
              }
            },
            text: {
              text: '',
              font: '12px Calibri, sans-serif'
            }
          }
        }
      ]
    }

    this.dataSourceService
      .createAsyncDataSource({
        type: 'cluster',
        id: '42',
        queryable: true,
        distance: 74,
        meta: {
          title: 'Cluster'
        },
      } as QueryableDataSourceOptions)
      .subscribe((dataSource: ClusterDataSource) => {
        const layer = this.layerService.createLayer({
          title: 'Clustered Markers Layer',
          source: dataSource,
          visible: true,
        });

        layer.ol.setStyle(feature => {
          const size = feature.get('features').length;
          clusterParam.clusterRanges[0].style.text.text = size.toString();
          return this.styleService.createClusterStyle(feature, clusterParam, baseStyle);
        })

        const featuresOlList = this.store.entities$.value
          .map(feature => featureToOl(feature as any, this.map.projection));

        (dataSource.ol as any).source.addFeatures(featuresOlList);
        this.map.removeLayer(this.map.getLayerById('42'));
        this.map.addLayer(layer);
        this.store.bindLayer(layer as VectorLayer);
        this.selectionStrategy.activate();
      });
  }

  private updateSelectedState(placeId: number): void {
    console.log('change if ', placeId, ' is different of ', this.currentSelectedPlaceId);
    if (this.currentSelectedPlaceId !== placeId) {
      this.map.overlay.clear();
      if (placeId) {
        if (this.currentSelectedPlaceId !== placeId) {
          const currentEntity = this.store.entities$.value.find(entity => entity.meta.id === placeId);
          // this.googleAnalyticsService.eventEmitter('place_selected', 'on_map', 'click', currentEntity.properties.name);
          this.displaySelectedOnMap(currentEntity);
          this.currentSelectedPlaceId = currentEntity.meta.id;
          this.portalService.onMapPlaceClicked(this.currentSelectedPlaceId);

          if (this.isMobile()) {
            this.portalService.setOpenSidePanelState(OpenSidePanelState.Mid);
          }
        }
      } else {
        this.currentSelectedPlaceId = null;
      }
    }
  }
}
