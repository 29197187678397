<header role="banner">
  <a
    class="logo-qc"
    href="https://www.quebec.ca/"
    target="_top"
    rel="external">
    <img
      alt="Logo du gouvernement du Québec"
      height="39px"
      src="assets/images/logo-gouv-qc-piv.svg"
      width="200px" />
  </a>

  <a
    class="topo-qc"
    href="https://toponymie.gouv.qc.ca/"
    target="_top"
    rel="external">
    Commission de toponymie
  </a>
</header>
