import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { FilterToggleComponent } from './filter-toggle/filter-toggle.component';
import { FiltersListDisplayerComponent } from './filters-list-displayer.component';



@NgModule({
  declarations: [FiltersListDisplayerComponent, FilterToggleComponent],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    SwiperModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    FiltersListDisplayerComponent
  ]
})
export class FiltersListDisplayerModule { }
