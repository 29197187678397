<div class="commemorative-places">
  <h2 class="commemorative-places__title">{{ ((mapContext$ | async) + '.places-list-title') | translate }}</h2>

  <ul class="commemorative-places__list">
    <li
      class="commemorative-places__item"
      *ngFor="let commemorativePlace of commemorativePlacesList">
      <mat-icon svgIcon="pin"></mat-icon>
      <button class="large"
        mat-icon-button
        (click)="setSelectedCommemorativePlace(commemorativePlace.id)">
        <b class="commemorative-places__name">{{ commemorativePlace.name }}</b>
        <span class="commemorative-places__city">{{ commemorativePlace.description.city }}</span>
      </button>
      <button
        mat-icon-button
        *ngIf="commemorativePlace.prononciation"
        (click)="jouerPrononciation(commemorativePlace.prononciation)">
        <mat-icon svgIcon="volume"></mat-icon>
      </button>
    </li>
  </ul>
</div>
