<div *ngIf="(getMapFiltersList$ | async) as mapFiltersList" class="filters-list-displayer ">

  <div #filtersListDisplayerSwiper *ngIf="mapFiltersList.length > 0"
    class="swiper-container filters-list-displayer__slider-container" [swiper]="swiperConfig" [(index)]="index">
    <app-filter-toggle class="filters-list-displayer__first-slide" [mapFilter]="mapFiltersList[0]"></app-filter-toggle>
    <div class="swiper-wrapper filters-list-displayer__slide-wrapper">
      <div class="swiper-slide filters-list-displayer__false-slide "></div>
      <div class="swiper-slide filters-list-displayer__slide" *ngFor="let mapFilter of mapFiltersList | slice: 1; let idx=index; trackBy: trackByFn">
        <app-filter-toggle [mapFilter]="mapFilter"></app-filter-toggle>
      </div>
    </div>
  </div>

</div>