<mat-sidenav-container>
  <mat-sidenav-content class="tm-portal">

    <button class="tm-portal__menu-button" *ngIf="(getOpenSidePanelState$ | async) === 'close'" mat-icon-button id="menu-button"
      (click)="OpenOrCloseSidePanelState()">
      <mat-icon svgIcon="home"></mat-icon>
      <span>{{ 'global.home' | translate }}</span>
    </button>

    <app-sidenav [(opened)]="sidenavOpened">
    </app-sidenav>

    <igo-map-browser
      #mapBrowser
      [map]="map"
      [view]="view"
      igoQuery
      [queryFeatures]="true"
      (query)="handleQueryResults($event)"
      [@mapStateX]="(getOpenSidePanelState$ | async)"
      (@mapStateX.start)="updateMapBrowserClass()"
      [@mapStateY]="(getOpenSidePanelState$ | async)"
      (@mapStateY.start)="updateMapBrowserClass()" >
      <div class="tm-portal__button-wrapper">
        <igo-zoom-button *ngIf="!isMobile()" [map]="map" color="primary">
        </igo-zoom-button>
        <igo-geolocate-button [map]="map" color="primary">
        </igo-geolocate-button>
      </div>
      <div class="tm-portal__button-wrapper tm-portal__button-wrapper--layer-switcher">
        <igo-baselayers-switcher
          [map]="map"
          [useStaticIcon]="true">
        </igo-baselayers-switcher>
      </div>
    </igo-map-browser>

    <ng-container *ngIf="(getOpenSidePanelState$ | async) as panelState">
      <button *ngIf="(panelState === 'full' || panelState === 'mid') && currentSelected"
        class="tm-portal__map-button" (click)="changeOpenSidePanelStateToMin()" mat-icon-button>
        <span>{{ 'global.back-to-map-btn' | translate }}</span>
        <mat-icon svgIcon="map"></mat-icon>
      </button>
    </ng-container>

    <app-reel-footer></app-reel-footer>

  </mat-sidenav-content>

</mat-sidenav-container>
