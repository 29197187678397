import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Celebrity, MapContext } from '../../portal.interface';
import { PortalService } from '../../portal.service';

@Component({
  selector: 'app-description',
  // todo better accordion animation...
  animations: [
    trigger(
      'accordionAnimation',
      [
        transition(
          ':enter', [
          style({ transform: 'translateY(100%)', opacity: 0 }),
          animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
        ]),
        transition(
          ':leave', [
          style({ transform: 'translateY(0)', opacity: 1 }),
          animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 })),
        ])]
    )
  ],
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescriptionComponent implements OnInit {

  @Input()
  public celebrity: Celebrity;

  public displayMore: boolean = false;
  public displayFilters: boolean = false;

  public mapContext$: Observable<MapContext> = this.portalService.getMapContext();

  constructor(
    private portalService: PortalService,
  ) { }

  public ngOnInit(): void {
    this.displayFilters = this.portalService.getDisplayFitlers();

  }

  public toggleBioDisplay(): void {
    this.displayMore = !this.displayMore;
  }

}
