import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { HelpersModule } from '../../../helpers/helpers.module';
import { FiltersListDisplayerModule } from '../filters-list-displayer/filters-list-displayer.module';
import { PortalService } from '../portal.service';
import { ReelFooterComponent } from './reel-footer.component';
import { ReelProfileComponent } from './reel-profile/reel-profile.component';

@NgModule({
  declarations: [ReelFooterComponent, ReelProfileComponent],
  imports: [
    CommonModule,
    SwiperModule,
    HelpersModule,
    FiltersListDisplayerModule,
  ],
  providers: [
    PortalService,
  ],
  exports: [ReelFooterComponent]
})
export class ReelFooterModule {}


