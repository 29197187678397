import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Media, MediaService } from '@igo2/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import { Celebrity } from '../portal.interface';
import { PortalService } from '../portal.service';


@Component({
  selector: 'app-reel-footer',
  templateUrl: './reel-footer.component.html',
  styleUrls: ['./reel-footer.component.scss'],
  animations: [
    trigger('fade', [ 
      transition('void => *', [
        style({ opacity: 0 }), 
        animate('{{ delay }}ms', style({opacity: 0})),
        animate(300, style({opacity: 1}))
      ]) 
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReelFooterComponent implements OnInit, AfterViewInit, OnDestroy {

  public index: number = 0;
  public upToDateSlider: boolean = true;
  public displayFilters: boolean = false;
  public animationDelay: number = 0;

  public swiperConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: null,
    navigation: true,
    centeredSlides: true,
    centeredSlidesBounds: true,
    updateOnWindowResize: true,
    roundLengths: true,
  };

  public celebrityList$: Observable<Celebrity[]> = this.portalService.getCelebrityList();
  public isSatLayerActive$: Observable<boolean> = this.portalService.getCurrentVisibleLayer().pipe(
    map(currentVisibleLayer => currentVisibleLayer === 'Imagerie')
  );

  private selectedCelebrityIndex$$: Subscription;
  private celebrityList$$: Subscription;

  constructor(
    private portalService: PortalService,
    public cdRef: ChangeDetectorRef,
    private mediaService: MediaService,
  ) { }

  public ngOnInit(): void {
    this.selectedCelebrityIndex$$ = this.portalService.getSelectedCelebrityIndex()
      .subscribe((currentIndex) => this.onSelectedCelebrityIndexChange(currentIndex));

    this.displayFilters = this.portalService.getDisplayFitlers();
  }

  public ngAfterViewInit(): void {
    this.celebrityList$$ = this.celebrityList$.subscribe(celebrityList => {
      if (celebrityList.length) {
        console.log('update slide');
        this.calculateBestNumberOfSlides(celebrityList.length);

        this.upToDateSlider = false;
        this.index = 0;
        this.animationDelay = 0;
        setTimeout(() => {
          this.upToDateSlider = true;
          this.cdRef.detectChanges();
        }, 0);
      }
    });
  }

  private calculateBestNumberOfSlides(celebrityListLength: number): void {
    let slideWidth = 100;
    let slideMarging = 25;

    let screenWidth = window.innerWidth;
    let slidesPerView = 0;

    while (screenWidth > slideWidth) {
      screenWidth = screenWidth - (slideWidth + slideMarging);
      slidesPerView++;
    }

    screenWidth = screenWidth - slideMarging;

    if (screenWidth > slidesPerView) {
      slideMarging = slideMarging + (screenWidth / slidesPerView);
    }

    this.swiperConfig.slidesPerView = slidesPerView;
    this.swiperConfig.spaceBetween = slideMarging;

    if (celebrityListLength < slidesPerView) {
      this.displayNavigationArrows(false);
    } else {
      this.displayNavigationArrows(true);
    }

  }

  private displayNavigationArrows(state: boolean) {
    this.swiperConfig.navigation = state;
    this.swiperConfig.centeredSlides = state;
  }

  public ngOnDestroy(): void {
    this.selectedCelebrityIndex$$.unsubscribe();
    this.celebrityList$$.unsubscribe();
  }

  public onSelectedCelebrityIndexChange(currentIndex: number): void {
    if (this.index !== currentIndex) {
      this.index = currentIndex;
      this.cdRef.detectChanges();
    }
  }

  public trackByFn(index: number, item: Celebrity): string {
    return `${index}-${item.name}`;
  }

  public isMobile(): boolean {
    return this.mediaService.getMedia() === Media.Mobile;
  }

  public getAnimationDelay(): number {
    console.log('delay = ', this.animationDelay);
    return this.animationDelay += 50;
  }

}
