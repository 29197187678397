import { Component, Input, OnInit } from '@angular/core';

import { MapFilter } from '../../portal.interface';
import { PortalService } from '../../portal.service';

@Component({
  selector: 'app-filter-toggle',
  templateUrl: './filter-toggle.component.html',
  styleUrls: ['./filter-toggle.component.scss']
})
export class FilterToggleComponent implements OnInit {


  @Input()
  public mapFilter: MapFilter;

  constructor(
    private portalService: PortalService,
  ) { }

  public ngOnInit(): void {
  }

  public toggleFilter() {
    this.mapFilter.active = !this.mapFilter.active;
    console.log(this.mapFilter.name, ' is now ', this.mapFilter.active);
    this.portalService.toggleFilter(this.mapFilter);
  }

}
