import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IgoActionModule } from '@igo2/common';
import { IgoCoreModule } from '@igo2/core';
import { IgoMapModule, IgoQueryModule, IgoSearchModule } from '@igo2/geo';

import { ReelFooterModule } from './reel-footer/reel-footer.module';
import { AppSidenavModule } from './sidenav/sidenav.module';
import { TmPortalComponent } from './tm-portal.component';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule,
    IgoCoreModule,
    IgoMapModule,
    IgoQueryModule.forRoot(),
    IgoSearchModule.forRoot(),
    IgoActionModule,
    AppSidenavModule,
    ReelFooterModule,
  ],
  exports: [TmPortalComponent],
  declarations: [TmPortalComponent],
})
export class PortalModule {}
