import { Injectable } from '@angular/core';
import * as CookieConsent from 'vanilla-cookieconsent';

declare global {
    interface Window {
        dataLayer:any;
    }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  public eventEmitter(
    eventAction: string,
    eventCategory: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    if (CookieConsent.acceptedCategory('analytics')) {
      console.log('[GA] action:  ', eventAction, {
        event_category: eventCategory,
        endroitClique: eventLabel,
        selection: eventValue
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: eventAction,
        endroitClique: eventCategory,
        selection: eventLabel
      });
    }
  }
}
