import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Media, MediaService } from '@igo2/core';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { scrollToTop } from '../../../helpers/utilities';
import { Celebrity, MapContext, OpenSidePanelState, Place } from '../portal.interface';
import { PortalService } from '../portal.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavComponent implements OnInit, OnDestroy {

  @Input()
  get opened(): boolean {
    return this._opened;
  }
  set opened(value: boolean) {
    if (value === this._opened) {
      return;
    }

    this._opened = value;
    this.openedChange.emit(this._opened);
  }
  private _opened: boolean;

  @Output()
  public openedChange = new EventEmitter<boolean>();

  public currentSelected: Celebrity = null;

  public currentSelected$$: Subscription;

  public getSelectedPlace$: Observable<Place> = this.portalService.getSelectedPlace().pipe(tap(() => scrollToTop()));

  public getOpenSidePanelState$: Observable<OpenSidePanelState> = this.portalService.getOpenSidePanelState();

  public isSatLayerActive$: Observable<boolean> = this.portalService.getCurrentVisibleLayer().pipe(
    map(currentVisibleLayer => currentVisibleLayer === 'Imagerie')
  );

  public mapContext$: Observable<MapContext> = this.portalService.getMapContext();

  constructor(
    public mediaService: MediaService,
    private portalService: PortalService,
    public cdRef: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {

    this.currentSelected$$ = this.portalService.getSelectedCelebrity()
      .subscribe((currentSelected) => this.onSelectedCelebrityChange(currentSelected));
  }

  public ngOnDestroy(): void {
    this.currentSelected$$.unsubscribe();
  }

  public closeSideNav(): void {
    this.opened = false;
    this.portalService.setSelectedCelebrity(null);
    this.portalService.setSelectedPlaceId(null);
    this.portalService.setOpenSidePanelState(OpenSidePanelState.Close);
  }

  public selectPreviousCelebrity(): void {
    this.portalService.selectPreviousCelebrity();
  }

  public selectNextCelebrity(): void {
    this.portalService.selectNextCelebrity();
  }

  public selectPreviousCelebrityPlace(): void {
    this.portalService.selectPreviousCelebrityPlace();
  }

  public selectNextCelebrityPlace(): void {
    this.portalService.selectNextCelebrityPlace();
  }

  public isMobile(): boolean {
    return this.mediaService.getMedia() === Media.Mobile;
  }

  public isTablet(): boolean {
    return this.mediaService.getMedia() === Media.Tablet;
  }

  public getFilteredPlaces(idsList: number[]): Place[] {
    return this.portalService.getFilteredPlaces(idsList);
  }

  public unselectPlace(): void {
    console.log('unselectPLace');
    this.portalService.setSelectedPlaceId(null);
    if (this.isMobile()) {
      this.portalService.setOpenSidePanelState(OpenSidePanelState.Full);
    }
  }

  public changeOpenSidePanelStateToFull(): void {
    console.log('open sidePanel to full');
    this.portalService.setOpenSidePanelState(OpenSidePanelState.Full);
  }

  private onSelectedCelebrityChange(currentSelected: Celebrity): void {
    if (currentSelected) {
      this.opened = true;
      this.portalService.setOpenSidePanelState(OpenSidePanelState.Full);
    }
    if (this.currentSelected !== currentSelected) {
      scrollToTop();
      this.currentSelected = currentSelected;
      this.cdRef.detectChanges();
    }
  }
}
