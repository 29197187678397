import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable } from 'rxjs';

import { MapFilter } from '../portal.interface';
import { PortalService } from '../portal.service';

@Component({
  selector: 'app-filters-list-displayer',
  templateUrl: './filters-list-displayer.component.html',
  styleUrls: ['./filters-list-displayer.component.scss'],
})
export class FiltersListDisplayerComponent implements AfterViewInit, OnInit {

  public getMapFiltersList$: Observable<MapFilter[]> = this.portalService.getMapFiltersList();

  public swiperConfig:  SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: null,
    centeredSlides: true,
    centeredSlidesBounds: true,
    updateOnWindowResize: true,
    roundLengths: true,
    // width: 1086,
  };
  
  constructor(
    private portalService: PortalService
  ) { }

  public ngOnInit(): void {
    // this.swiperConfig.width = window.innerWidth + 90;

  }

  public ngAfterViewInit(): void {
  }

  public trackByFn(index: number, item: MapFilter): string {
    return `${index}-${item.name}`;
  }

}
