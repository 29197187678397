<div id="image-displayer" class="full-screen-image-displayer" *ngIf="(showImageDisplayer$ | async)">
  <div class="full-screen-image-displayer__wrapper" *ngIf="( PlaceToShowFullScreen$ | async) as  PlaceToShowFullScreen" >
      <div class="full-screen-image-displayer__header">
        <h1>{{ PlaceToShowFullScreen.name }}</h1>

        <button (click)="closeImageDisplayer()" mat-icon-button>
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </div>

      <figure>
        <img class="full-screen-image-displayer__image"[src]="PlaceToShowFullScreen.photo.src" [alt]="PlaceToShowFullScreen.photo.alt" appNoRightClick/>
        <figcaption class="p-img-source">{{ PlaceToShowFullScreen.photo.source }}</figcaption>
      </figure>
  </div>
</div>
