import { Component, Input, OnInit } from '@angular/core';

import { Place } from '../../portal.interface';
import { PortalService } from '../../portal.service';

@Component({
  selector: 'app-place-displayer',
  templateUrl: './place-displayer.component.html',
  styleUrls: ['./place-displayer.component.scss']
})
export class PlaceDisplayerComponent implements OnInit {

  @Input()
  public selectedPlace: Place;

  constructor(private portalService: PortalService) { }

  public ngOnInit(): void {
  }

  public openFullScreenImg(): void {
    console.log('will full screen: ', this.selectedPlace.photo.src);
    this.portalService.setPlaceToShowFullScreen(this.selectedPlace);
    this.portalService.setShowImageDisplayer(true);

  }
  public jouerPrononciation(urlAudio: string):void {
    new Audio(urlAudio).play();
  }

}
