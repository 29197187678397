export interface Celebrity {
    id: number;
    avatar: ImageSrc;
    name: CelebrityName;
    order: number;
    filter?: string;
    link: string;
    description : CelebrityDescription;
    highlightedPlacesIdsList: number[];
    commemorativePlacesIdsList: number[];
}

export interface Person {
  id: number,
  description: string;
  dates: string;
  order: number;
  filter: string;
  name: string;
  firstName: string;
  lastName: string;
  avatar: string;
  link: string;
  imgSrcLabel: string;
  highLightedPlacesIdsList: number[];
  commemorativePlacesIdsList: number[];
}

export interface Place {
    id: number;
    name: string;
    description: PlaceDescription;
    photo: ImageSrc;
    // highResPhoto: ImageSrc; < est-ce qu'on en aura besoin ?
    link: string;
    prononciation: string;
}

export interface Location {
    id: number;
    name: string,
    description: string;
    photoSrc: string;
    cityName: string;
    imgSrcLabel: string;
    // highResPhoto: ImageSrc; < est-ce qu'on en aura besoin ?
    link: string;
    coordinates: number[];
    prononciation: string;
}

export interface MapFilter {
    id: number;
    name: string;
    mapTypeId: number;
    mapType: string;
    active?: boolean;
}

export enum OpenSidePanelState {
    Close = "close",
    Min = "min",
    Mid = "mid",
    Full = "full"
}

export enum MapContext {
  Women = "women",
  PrimeMinisters = "primeMinisters",
  Patriots  = "patriots",
  Autochtones  = "autochtones",
}

interface ImageSrc {
    src: string;
    alt: string;
    source: string;
}

interface CelebrityDescription {
    date?: string;
    bio: string;
}

interface PlaceDescription {
    name: string,
    city: string
    bio: string
}

interface CelebrityName {
    firstName: string;
    lastName: string;
    fullName: string;
}