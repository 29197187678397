import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { MapFilter, Person } from './portal.interface';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  private headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  public getAllPersons(mapTypeId: number): Observable<Person[]> {

    let API_URL = `${environment.toumoro.apiUrl}/GetAllPersons?mapTypeId=${mapTypeId}`;
    console.log('url: ', API_URL);
    return this.http.get(API_URL, { headers: this.headers })
      .pipe(
        tap(console.log),
        catchError(this.error)
      )
  }

  public getAllLocations(mapTypeId: number): Observable<any> { //todo find type
    let API_URL = `${environment.toumoro.apiUrl}/GetAllLocations?mapTypeId=${mapTypeId}`;
    return this.http.get(API_URL, { headers: this.headers })
      .pipe(
        tap(console.log),
        catchError(this.error)
      )
  }

  public getAllFilters(mapTypeId: number): Observable<MapFilter[]> {
    
    let API_URL = `${environment.toumoro.apiUrl}/GetAllFilterForMapType?mapTypeId=${mapTypeId}`;
    return this.http.get(API_URL, { headers: this.headers })
      .pipe(
        tap(console.log),
        catchError(this.error)
      )
  }

  private error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
