import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { GoogleAnalyticsService } from '../../../../google-analytics.service';
import { Celebrity, MapContext } from '../../portal.interface';
import { PortalService } from '../../portal.service';

@Component({
  selector: 'app-reel-profile',
  templateUrl: './reel-profile.component.html',
  styleUrls: ['./reel-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReelProfileComponent implements OnInit, OnDestroy {
  public isSelected: boolean = false;

  @Input()
  public celebrity: Celebrity = null;

  @Input()
  public index: number = 0

  public mapContext$: Observable<MapContext> = this.portalService.getMapContext();

  private currentSelected$$: Subscription;

  constructor(
    private portalService: PortalService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public cdRef: ChangeDetectorRef,

  ) { }

  public ngOnInit(): void {

    this.currentSelected$$ = this.portalService.getSelectedCelebrity()
    .subscribe((currentSelected) => this.onSelectedCelebrityChange(currentSelected));
  }

  public ngOnDestroy(): void {
    this.currentSelected$$.unsubscribe();
  }

  public profilSelected(): void {
    console.log(this.celebrity.id, ' selected!');
    this.portalService.setSelectedCelebrity(this.celebrity.id);
    this.portalService.setSelectedPlaceId(null);

    this.googleAnalyticsService.eventEmitter('selection_personne', 'vignette_en_bas', this.portalService.findSelectedCelebrityFromId(this.celebrity.id).name.fullName);

  }

  private onSelectedCelebrityChange(currentSelected: Celebrity): void {
    let tmpIsSelected = false;
    if (currentSelected) {
      tmpIsSelected =  currentSelected.id === this.celebrity.id;
    }
    if (tmpIsSelected !== this.isSelected) {
      this.isSelected = tmpIsSelected;
      this.cdRef.detectChanges();
    }
  }
}
