import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PortalService } from '../pages/portal/portal.service';

@Component({
  selector: 'app-tm-spinner',
  templateUrl: './tm-spinner.component.html',
  styleUrls: ['./tm-spinner.component.scss']
})
export class TmSpinnerComponent implements OnInit {

  public isCelebrityListLoaded$: Observable<boolean> = this.portalService.getCelebrityList().pipe(map(list => list.length > 0));
  public isPlaceListLoaded$: Observable<boolean> = this.portalService.getSelectedEntitiesList().pipe(map(list => list.length > 0));

  constructor(private portalService: PortalService) { }

  ngOnInit(): void {
  }

}
