import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiLineEllipsis'
})
export class MultiLineEllipsisPipe implements PipeTransform {

     transform(value: string, ellipsisLength: number, replaceChar: string = '...'): string {
        return `${value.slice(0, ellipsisLength)}${replaceChar}`;
      }
 }
