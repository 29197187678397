import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule, DomSanitizer, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IgoStopPropagationModule } from '@igo2/common';
import { provideConfigOptions } from '@igo2/core';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FullScreenImageDisplayerComponent } from './full-screen-image-displayer/full-screen-image-displayer.component';
import { HeaderBannerComponent } from './header-banner/header-banner.component';
import { HelpersModule } from './helpers/helpers.module';
import { MapSelectorComponent } from './map-selector/map-selector.component';
import { MapComponent } from './map-selector/map/map.component';
import { PortalModule } from './pages';
import { TmSpinnerComponent } from './tm-spinner/tm-spinner.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderBannerComponent,
    FullScreenImageDisplayerComponent,
    TmSpinnerComponent,
    MapSelectorComponent,
    MapComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IgoStopPropagationModule,
    PortalModule,
    HammerModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    AppRoutingModule,
    HttpClientModule,
    HelpersModule,
  ],
  providers: [
    provideConfigOptions({
      default: environment.igo,
      path: './config/config.json'
    }),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    // the svgs have to be but in "../assets/icons".
    const svgIconsList: string[] = [
      'arrow-back',
      'arrow-forward',
      'chevron-down',
      'chevron-up',
      'expand',
      'home',
      'map',
      'minus',
      'pin',
      'plus',
      'volume'
    ];

    svgIconsList.forEach((svgicon) =>
      matIconRegistry.addSvgIcon(
        svgicon,
        domSanitizer.bypassSecurityTrustResourceUrl(
          `../assets/icons/${svgicon}.svg`
        )
      )
    );
  }
}
