export function scrollToTop(): void {
  const sidenavNode = document.querySelector('.mat-drawer');
  if (sidenavNode) {
    sidenavNode.scrollTo(0, 0);
  }
}

export function copyArrayOfObject(toCopy: any[]): any[] {
  return toCopy.map(current => ({... current}));
}
