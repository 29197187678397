import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { GoogleAnalyticsService } from '../../../../google-analytics.service';
import { MapContext, Place } from '../../portal.interface';
import { PortalService } from '../../portal.service';

@Component({
  selector: 'app-commemorative-places',
  templateUrl: './commemorative-places.component.html',
  styleUrls: ['./commemorative-places.component.scss']
})
export class CommemorativePlacesComponent implements OnInit {

  @Input()
  public commemorativePlacesList: Place[];

  public mapContext$: Observable<MapContext> = this.portalService.getMapContext();

  constructor(
    private portalService: PortalService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) { }

  ngOnInit(): void {
    console.log('commemorative places: ', this.commemorativePlacesList);
  }

  public setSelectedCommemorativePlace(placeId: number):void {
    this.googleAnalyticsService.eventEmitter('selection_lieu', 'liste_panneauGauche', this.portalService.findSelectedPlaceFromId(placeId).name);

    this.portalService.setSelectedPlaceId(placeId);
  }
  public jouerPrononciation(urlAudio: string):void {
    new Audio(urlAudio).play();
  }
}
