import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IgoFlexibleModule, IgoInteractiveTourModule, IgoPanelModule, IgoToolModule } from '@igo2/common';
import { IgoContextManagerModule } from '@igo2/context';
import { IgoLanguageModule } from '@igo2/core';
import { IgoFeatureModule } from '@igo2/geo';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { AppRoutingModule } from '../../../app-routing.module';
import { HelpersModule } from '../../../helpers/helpers.module';
import { CommemorativePlacesComponent } from './commemorative-places/commemorative-places.component';
import { DescriptionComponent } from './description/description.component';
import { HighligthedPlacesSliderComponent } from './highligthed-places-slider/highligthed-places-slider.component';
import { PlaceDisplayerComponent } from './place-displayer/place-displayer.component';
import { SidenavComponent } from './sidenav.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatTooltipModule,
    IgoLanguageModule,
    IgoPanelModule,
    IgoFlexibleModule,
    IgoContextManagerModule,
    IgoToolModule,
    IgoFeatureModule,
    IgoInteractiveTourModule,
    SwiperModule,
    AppRoutingModule,
    HelpersModule,
  ],
  exports: [SidenavComponent],
  declarations: [SidenavComponent, DescriptionComponent, HighligthedPlacesSliderComponent, CommemorativePlacesComponent, PlaceDisplayerComponent]
})
export class AppSidenavModule {}
