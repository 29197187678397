<div [ngClass]="{'reel-footer--filters-list-displayed': displayFilters}">

  <div class="reel-footer" *ngIf="(celebrityList$ | async) as celebrityList"
    [ngClass]="{'reel-footer--filters-list-displayed': displayFilters}">
    <div *ngIf="upToDateSlider && celebrityList.length > 0" #reelFooterSwiper [swiper]="swiperConfig" [(index)]="index"
      class="swiper-container reel-footer__profiles-List-container"
      [ngClass]="{'reel-footer__profiles-List-container--centered' : !swiperConfig.navigation}"
      (update)="onSliderUpdate($event)">
      <div class="swiper-wrapper reel-footer__swiper-wrapper">
        <div class="swiper-slide reel-footer__slide-wrapper"
          *ngFor="let celebrity of celebrityList; let idx=index; trackBy: trackByFn">
          <app-reel-profile [@fade]="{value: '', params:{delay: getAnimationDelay()}}" [celebrity]="celebrity" [index]="idx"></app-reel-profile>
        </div>
      </div>
    </div>

    <ng-container *ngIf="isMobile() === false && swiperConfig.navigation">
      <div class="swiper-button-prev reel-footer__button"
        [ngClass]="{'reel-footer__button--filters-list-displayed': displayFilters}"></div>
      <div class="swiper-button-next reel-footer__button"
        [ngClass]="{'reel-footer__button--filters-list-displayed': displayFilters}"></div>
    </ng-container>
  </div>
  <div class="reel-footer__container" [ngClass]="{'reel-footer__container--sat-layer' : (isSatLayerActive$ | async)}">
  </div>
</div>
<app-filters-list-displayer *ngIf="displayFilters"></app-filters-list-displayer>