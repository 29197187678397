import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthOptions } from '@igo2/auth';
import { LanguageService, MessageService } from '@igo2/core';
import { userAgent } from '@igo2/utils';
import { zip } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import * as CookieConsent from 'vanilla-cookieconsent';

import { environment } from '../environments/environment';
import { GoogleAnalyticsService } from './google-analytics.service';
import { PortalService } from './pages/portal/portal.service';
import { SEOService } from './seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public authConfig: AuthOptions;
  private themeClass = 'ctpq-theme';

  constructor(
    protected languageService: LanguageService,
    private renderer: Renderer2,
    private metaService: Meta,
    private messageService: MessageService,
    private seoService: SEOService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private portalService: PortalService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.languageService.setLanguage('fr');
    this.renderer.addClass(document.body, this.themeClass);

    this.detectOldBrowser();
  }

  public ngOnInit(): void {
    if (!environment.production) {
      this.metaService.addTags([
        { name: 'robots', content: 'noindex' },
        { name: 'googlebot', content: 'noindex' }
      ]);
    }
    this.watchNavigation();
  }

  private watchNavigation() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        // TODO - mschmit - check if it's not already done by default
        // tap((event: NavigationEnd)=> this.googleAnalyticsService.eventEmitter(null, 'page_path', null, event.urlAfterRedirects)),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        console.log('received event: ', event);
        this.loadSEO(event);
        this.portalService.resetServiceValues();
        if (event['mapContext']) {
          this.portalService.getAllDatas(event['mapContext']);
        }
      });
  }

  private loadSEO(event: { [x: string]: any }) {
    this.seoService.updateTitle(event['title']);
    this.seoService.updateOgUrl(event['ogUrl']);
    this.seoService.updateDescription(event['description']);

    let lienCanonique: HTMLLinkElement = document.querySelector(
      'link[rel=canonical]'
    );
    if (lienCanonique === null) {
      lienCanonique = this.renderer.createElement('link');
      this.renderer.setAttribute(lienCanonique, 'rel', 'canonical');
      this.renderer.appendChild(document.head, lienCanonique);
    }
    this.renderer.setAttribute(
      lienCanonique,
      'href',
      document.baseURI + event['ogUrl'].substr(1)
    );
  }

  private detectOldBrowser() {
    const oldBrowser = userAgent.satisfies({
      ie: '<11',
      chrome: '<64',
      firefox: '<60'
    });

    if (oldBrowser) {
      const translate = this.languageService.translate;
      const title$ = translate.get('oldBrowser.title');
      const message$ = translate.get('oldBrowser.message');
      zip(title$, message$)
        .pipe(
          map(([title, message]) => ({
            title,
            message
          }))
        )
        .subscribe((rep) =>
          this.messageService.alert(rep.message, rep.title, {
            timeOut: 15000
          })
        );
    }
  }
  private ngAfterViewInit(): void {
    console.log('AfterViewInit');
    CookieConsent.run({
      revision: 1,

      cookie: {
        useLocalStorage: true
      },

      categories: {
        obligatoires: {
          enabled: true,
          readOnly: true
        },
        analytics: {
          autoClear: {
            cookies: [
              {
                name: /^_ga/ // regex: tous les cookies commençant par '_ga'
              },
              {
                name: '_gid' // string: exact
              }
            ]
          }
        }
      },
      guiOptions: {
        consentModal: {
          layout: 'cloud',
          position: 'bottom center',
          equalWeightButtons: true,
          flipButtons: false
        },
        preferencesModal: {
          layout: 'box',
          equalWeightButtons: true,
          flipButtons: false
        }
      },
      language: {
        default: 'fr',
        translations: {
          fr: {
            consentModal: {
              title: 'Gestion des témoins de navigation',
              description:
                'Ce site Web utilise des témoins pour optimiser votre expérience de navigation. Certains sont essentiels au bon fonctionnement du site, alors que d’autres servent à collecter des statistiques. Vous êtes libre de <a data-cc="show-preferencesModal" class="cc-link">choisir les témoins non essentiels</a> que vous acceptez d’activer. Pour plus d’information, consultez notre <a href="https://www.oqlf.gouv.qc.ca/politique.html" class="cc-link">Politique de confidentialité</a>.',
              acceptAllBtn: 'Tout accepter',
              acceptNecessaryBtn: 'Tout refuser'
            },
            preferencesModal: {
              title: 'Gestion des témoins de navigation',
              acceptAllBtn: 'Tout accepter',
              acceptNecessaryBtn: 'Tout refuser',
              savePreferencesBtn: 'Enregistrer ma sélection',
              closeIconLabel: 'Fermer',
              sections: [
                {
                  title: 'Témoins essentiels (obligatoire)',
                  description:
                    'Les témoins essentiels servent à organiser et à afficher les pages du site Web de l’Office de façon optimale en tenant compte de vos préférences. Ces témoins contribuent à rendre le site Web de l’Office utilisable et sécuritaire en activant des fonctions de base comme celles associées à des formulaires. Un site Web ne peut pas fonctionner correctement sans ces témoins. Ils sont donc obligatoires',
                  linkedCategory: 'obligatoires'
                },
                {
                  title: 'Témoins de suivi',
                  description:
                    'Les témoins de suivi, tels que ceux de Google Analytics et de Google Tag Manager, servent à repérer des problèmes ainsi qu’à recueillir des informations et des statistiques sur les habitudes d’utilisation des contenus Web. L’Office procède à l’analyse de ces données dans le but d’améliorer le fonctionnement de son site Web. Ces données anonymes peuvent être conservées à l’extérieur du Québec et aussi être partagées sur des comptes comme Google Ads et Google Search Console.',
                  linkedCategory: 'analytics'
                },
                {
                  title: 'Pour en savoir plus',
                  description:
                    'Consultez notre <a href="https://www.oqlf.gouv.qc.ca/politique.html" target="_blank" class="cc-link">Politique de confidentialité</a>.'
                }
              ]
            }
          }
        }
      }
    });
  }
}
