import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

import { GoogleAnalyticsService } from '../../google-analytics.service';
import { copyArrayOfObject } from '../../helpers/utilities';
import { CrudService } from './crud.service';
import { Celebrity, Location, MapContext, MapFilter, OpenSidePanelState, Person, Place } from './portal.interface';

const HIDE_FILTERS_FOR = [MapContext.PrimeMinisters, MapContext.Patriots];

@Injectable({
    providedIn: 'root'
})
export class PortalService {

    public readonly selectedCelebrity$: BehaviorSubject<Celebrity> = new BehaviorSubject(null);
    public readonly selectedCelebrityIndex$: BehaviorSubject<number> = new BehaviorSubject(0);
    public readonly celebrityList$: BehaviorSubject<Celebrity[]> = new BehaviorSubject([]);
    public readonly selectedEntitiesList$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public readonly selectedPlaceId$: BehaviorSubject<number> = new BehaviorSubject(null);
    public readonly selectedPlace$: BehaviorSubject<Place> = new BehaviorSubject(null);
    public readonly showImageDisplayer$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public readonly PlaceToShowFullScreen$: BehaviorSubject<Place> = new BehaviorSubject(null);
    public readonly OpenSidePanelState$: BehaviorSubject<OpenSidePanelState> = new BehaviorSubject(OpenSidePanelState.Full);
    public readonly currentMapContex$: BehaviorSubject<MapContext> = new BehaviorSubject(null);
    public readonly mapFiltersList$: BehaviorSubject<MapFilter[]> = new BehaviorSubject([]);
    public readonly currentVisibleLayer$: BehaviorSubject<string> = new BehaviorSubject(null);

    private celebrityList: Celebrity[] = [];
    private defaultCelebrityList: Celebrity[] = [];
    private selectedCelebrity: Celebrity;
    private selectedCelebrityIndex: number = -1;
    private PlacesList: Place[] = [];
    private entitiesList: any[] = [];
    private defaultEntitiesList: any[] = [];
    private selectedPlaceId: number = 0;
    private currentMapContex: MapContext = null;
    private displayFilters: boolean = true;
    private mapFiltersList: MapFilter[] = [];
    private defaultMapFiltersList: MapFilter[] = [];


    constructor(
        private crudService: CrudService,
        private googleAnalyticsService: GoogleAnalyticsService) {

    }

    public getAllDatas(mapContext: MapContext): void {
        this.setMapContex(mapContext);
        const mapTypeId = this.getMapTypeID();

        this.crudService.getAllPersons(mapTypeId).subscribe((personsList: Person[]) => {
            console.log('get datas for', mapTypeId);
            personsList.forEach((person: Person) => {
                this.defaultCelebrityList.push({
                    id: person.id,
                    name: {
                        firstName: person.firstName,
                        lastName: person.lastName,
                        fullName: person.name,
                    },
                    avatar: {
                        src: person.avatar,
                        alt: `portait de ${person.name}`,
                        source: person.imgSrcLabel,
                    },
                    order: person.order,
                    filter: person.filter,
                    link: person.link,
                    description: {
                        bio: person.description
                    },
                    highlightedPlacesIdsList: person.highLightedPlacesIdsList || [],
                    commemorativePlacesIdsList: person.commemorativePlacesIdsList
                })
            })
            this.celebrityList = copyArrayOfObject(this.defaultCelebrityList);
            this.setCelebrityList(this.celebrityList)
        });

        console.log('[GET] locations');
        this.crudService.getAllLocations(mapTypeId).subscribe((source: any) => { //todo find type
            console.log('[GET] locations : FINISH');
            source.forEach((location: Location) => {
                this.PlacesList.push({
                    id: location.id,
                    name: location.name,
                    description: {
                        name: location.name,
                        city: location.cityName,
                        bio: location.description,
                    },
                    photo: {
                        src: location.photoSrc,
                        alt: `photo de ${location.name}`,
                        source: location.imgSrcLabel,
                    },
                    link: location.link,
                    prononciation: location.prononciation,
                })

                this.defaultEntitiesList.push({
                    meta: { id: location.id },
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: location.coordinates,
                    },
                    projection: 'EPSG:4326',
                    properties: {
                        id: 1,
                        name: location.name,
                        description: location.description
                    }
                })
            });

            this.entitiesList = copyArrayOfObject(this.defaultEntitiesList);
            this.setSelectedEntitiesList(this.entitiesList);
        })

        if (this.displayFilters) {
            this.crudService.getAllFilters(mapTypeId).subscribe((filtersList: MapFilter[]) => {
                console.log('filters: ', filtersList);
                const defaultFilter: MapFilter = {
                    id: 0,
                    mapType: null,
                    mapTypeId: 0,
                    name: "Tous",
                    active: true,
                }
                this.defaultMapFiltersList = [defaultFilter, ...filtersList];
                this.mapFiltersList = copyArrayOfObject(this.defaultMapFiltersList)
                this.setMapFiltersList(this.mapFiltersList);
            })
        }
    }

    public toggleFilter(mapFilter: MapFilter): void {
        let entititesListIds: number[] = [];
        if (mapFilter.id !== 0) {
            this.mapFiltersList.map(currentMapFilter => {
                if (currentMapFilter.id === 0) {
                    currentMapFilter.active = false;
                }
                if (currentMapFilter.id === mapFilter.id) {
                    currentMapFilter.active = mapFilter.active;
                }
                return currentMapFilter;
            })

            this.celebrityList = this.defaultCelebrityList.filter(
                currentCelebrity => this.getActiveFiltersNameList().includes(currentCelebrity.filter)
            );
            
            
            entititesListIds = this.getEntitiesListIds();

            this.entitiesList = this.getFilteredEntitiesList(entititesListIds);
        }


        if (mapFilter.id === 0 || !this.getActiveFiltersNameList().length) {
            this.mapFiltersList = copyArrayOfObject(this.defaultMapFiltersList);
            this.celebrityList = copyArrayOfObject(this.defaultCelebrityList);
            this.entitiesList = copyArrayOfObject(this.defaultEntitiesList);
        }
        console.log('update filtered data');
        this.setCelebrityList(this.celebrityList);
        this.setMapFiltersList(this.mapFiltersList);
        this.filterSelectedEntititesList(entititesListIds);
        this.setSelectedCelebrity(null);
    }

    public getMapFiltersList(): Observable<MapFilter[]> {
        return this.mapFiltersList$.asObservable();
    }

    public setMapFiltersList(mapFiltersList: MapFilter[]): void {
        this.mapFiltersList$.next(mapFiltersList);
    }

    public setCurrentVisibleLayer(layerName: string): void {
        this.currentVisibleLayer$.next(layerName);
    }

    public getCurrentVisibleLayer(): Observable<string> {
        return this.currentVisibleLayer$.asObservable()
    }

    public getDisplayFitlers(): boolean {
        return this.displayFilters;
    }

    public setDisplayFilters(displayFilters: boolean): void {
        this.displayFilters = displayFilters;
    }

    public setMapContex(mapContext: MapContext): void {
        if (HIDE_FILTERS_FOR.filter(currentFilter => currentFilter === mapContext).length) {
            this.setDisplayFilters(false);
        } else {
            this.setDisplayFilters(true);
        }
        console.log('set context to: ', mapContext);
        this.currentMapContex = mapContext;
        this.currentMapContex$.next(mapContext);
    }

    public getMapContext(): Observable<MapContext> {
        return this.currentMapContex$.asObservable();
    }

    public getOpenSidePanelState(): Observable<OpenSidePanelState> {
        return this.OpenSidePanelState$.asObservable();
    }

    public setOpenSidePanelState(state: OpenSidePanelState): void {
        this.OpenSidePanelState$.next(state);
    }

    public getPlaceToShowFullScreen(): Observable<Place> {
        return this.PlaceToShowFullScreen$.asObservable();
    }

    public setPlaceToShowFullScreen(place: Place): void {
        this.PlaceToShowFullScreen$.next(place);
    }

    public getCelebrityList(): Observable<Celebrity[]> {
        return this.celebrityList$.asObservable();
    }

    public setCelebrityList(celebrityList: Celebrity[]): void {
        this.celebrityList$.next(celebrityList);
    }

    public getShowImageDisplayer(): Observable<boolean> {
        return this.showImageDisplayer$;
    }

    public setShowImageDisplayer(value: boolean): void {
        this.showImageDisplayer$.next(value);
    }

    public getSelectedCelebrity(): Observable<Celebrity> {
        return this.selectedCelebrity$.asObservable();
    }

    public getSelectedCelebrityIndex(): Observable<number> {
        return this.selectedCelebrityIndex$.asObservable();
    }

    public setSelectedCelebrityIndex(index: number): void {
        this.selectedCelebrityIndex$.next(index);
    }

    public setSelectedCelebrity(id: number): void {
        this.selectedCelebrityIndex = this.celebrityList.findIndex((currentCelebrity) => currentCelebrity.id === id);
        this.selectedCelebrity = this.findSelectedCelebrityFromId(id);
        this.setSelectedCelebrityIndex(this.selectedCelebrityIndex);
        this.selectedCelebrity$.next(this.selectedCelebrity);
    }

    public selectNextCelebrity(): void {
        this.selectedCelebrityIndex = (this.selectedCelebrityIndex + 1) <= (this.celebrityList.length - 1) ? (this.selectedCelebrityIndex + 1) : 0
        this.setSelectedCelebrityIndex(this.selectedCelebrityIndex);
        this.googleAnalyticsService.eventEmitter('selection_personne', 'suivant_panneauGauche', this.celebrityList[this.selectedCelebrityIndex].name.fullName);

        this.selectedCelebrity$.next(this.celebrityList[this.selectedCelebrityIndex])
    }

    public selectPreviousCelebrity(): void {
        this.selectedCelebrityIndex = (this.selectedCelebrityIndex - 1) >= 0 ? (this.selectedCelebrityIndex - 1) : (this.celebrityList.length - 1);
        this.setSelectedCelebrityIndex(this.selectedCelebrityIndex);
        this.googleAnalyticsService.eventEmitter('selection_personne', 'precedent_panneauGauche', this.celebrityList[this.selectedCelebrityIndex].name.fullName);

        this.selectedCelebrity$.next(this.celebrityList[this.selectedCelebrityIndex])
    }

    public selectNextCelebrityPlace(): void {
        const index = this.selectedCelebrity.commemorativePlacesIdsList.findIndex(currentPlaceId => currentPlaceId === this.selectedPlaceId);
        const newIndex = (index + 1) <= (this.selectedCelebrity.commemorativePlacesIdsList.length - 1) ? (index + 1) : 0;
        this.googleAnalyticsService.eventEmitter('selection_lieu', 'suivant_panneauGauche', this.findSelectedPlaceFromId(this.selectedCelebrity.commemorativePlacesIdsList[newIndex]).name);

        this.setSelectedPlaceId(this.selectedCelebrity.commemorativePlacesIdsList[newIndex]);
    }

    public selectPreviousCelebrityPlace(): void {
        const index = this.selectedCelebrity.commemorativePlacesIdsList.findIndex(currentPlaceId => currentPlaceId === this.selectedPlaceId);
        const newIndex = (index - 1) >= 0 ? (index - 1) : (this.selectedCelebrity.commemorativePlacesIdsList.length - 1);
        this.googleAnalyticsService.eventEmitter('selection_lieu', 'precedent_panneauGauche', this.findSelectedPlaceFromId(this.selectedCelebrity.commemorativePlacesIdsList[newIndex]).name);

        this.setSelectedPlaceId(this.selectedCelebrity.commemorativePlacesIdsList[newIndex]);
    }


    public getFilteredPlaces(idsList: number[]): Place[] {
        return idsList.reduce((acc, curr) => {
            const currentPlace = this.PlacesList.find(currentplace => currentplace.id === curr);
            if (currentPlace) {
                acc.push(currentPlace);
            }
            return acc;
        }, [])
    }

    public setSelectedEntitiesList(EntitiesList: any): void {
        console.log('set entities: ', EntitiesList);
        this.selectedEntitiesList$.next(EntitiesList);
    }

    public getSelectedEntitiesList(): Observable<any> {
        return this.selectedEntitiesList$.asObservable();
    }

    public getSelectedPlaceId(): Observable<number> {
        return this.selectedPlaceId$.asObservable();
    }

    public setSelectedPlaceId(placeId: number): void {
        console.log('looking place with id: ', placeId);
        const selectedPlace: Place = this.findSelectedPlaceFromId(placeId);
        console.log('found: ', selectedPlace);
        this.setSelectedPlace(selectedPlace);
        this.selectedPlaceId$.next(placeId);
        this.selectedPlaceId = placeId;
    }


    public getSelectedPlace(): Observable<Place> {
        return this.selectedPlace$.asObservable();
    }

    public setSelectedPlace(place: Place): void {
        this.selectedPlace$.next(place);
    }

    public filterSelectedEntititesList(idsList: number[]): void {
        if (idsList.length === 0) {
            this.setSelectedEntitiesList(this.entitiesList);
        } else {
            const filteredEntities = this.getFilteredEntitiesList(idsList);
            this.setSelectedEntitiesList(filteredEntities);
        }

    }

    public onMapPlaceClicked(placeId: number): void {
        this.setSelectedPlaceId(placeId);
        this.setSelectedCelebrity(this.findSelectedCelebrityIdFromPlaceId(placeId));
    }

    public resetServiceValues(): void {
        this.celebrityList = [];
        this.defaultCelebrityList = [];
        this.selectedCelebrity = null;
        this.selectedCelebrityIndex = -1;
        this.PlacesList = [];
        this.entitiesList = [];
        this.defaultEntitiesList = [];
        this.selectedPlaceId = 0;
        this.currentMapContex = null;
        this.mapFiltersList = [];
        this.defaultMapFiltersList = [];

        this.selectedCelebrity$.next(null);
        this.selectedCelebrityIndex$.next(0);
        this.celebrityList$.next([]);
        this.selectedEntitiesList$.next([]);
        this.selectedPlaceId$.next(null);
        this.selectedPlace$.next(null);
        this.showImageDisplayer$.next(false);
        this.PlaceToShowFullScreen$.next(null);
        this.OpenSidePanelState$.next(OpenSidePanelState.Full);
    }

    public findSelectedPlaceFromId(currentId: number): Place {
        return !currentId ? null : this.PlacesList.find(place => place.id === currentId);
    }

    public findSelectedCelebrityFromId(id: number): Celebrity {
        return this.celebrityList.find((currentCelebrity) => currentCelebrity.id === id);
    }

    private findSelectedCelebrityIdFromPlaceId(currentId: number): number {
        return this.celebrityList.find(celebrity => celebrity.commemorativePlacesIdsList.some(placeId => placeId === currentId)).id;
    }

    private getMapTypeID(): number {
        const mapContext = this.currentMapContex;
        let mapTypeId = 1;

        console.log(mapContext);
        if (mapContext === MapContext.Women) {
            mapTypeId = 2;
        } else if (mapContext === MapContext.Patriots) {
            mapTypeId = 3;
        } else if (mapContext === MapContext.Autochtones) {
            mapTypeId = 5;
        }

        return mapTypeId
    }

    private getFilteredEntitiesList(idsList: number[]): any[] {
        let entities = this.defaultEntitiesList.reduce((acc, curr) => {
            if (idsList.find(currentListId => currentListId === curr.meta.id)) {
                 acc.push(curr);
             }
             return acc;
         }, []);
 
         return entities;
     }

    private getActiveFiltersNameList(): string[] {
        return this.mapFiltersList.reduce((acc, curr) => {
            if (curr.active) {
                acc.push(curr.name);
            }
            return acc;
        }, [])
    }

    private getEntitiesListIds(): number[] {
        return this.celebrityList.reduce((acc, curr) => {
            acc = acc.concat(curr.commemorativePlacesIdsList);
            return acc;
        }, []);
    }
}

