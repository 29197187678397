<div class="place-displayer">
  <button
    mat-icon-button
    *ngIf="selectedPlace.prononciation"
    (click)="jouerPrononciation(selectedPlace.prononciation)">
    <mat-icon svgIcon="volume"></mat-icon>
  </button>
  <h1 class="place-displayer__title">{{ selectedPlace.name }}</h1>


  <div class="place-displayer__location">
    {{ selectedPlace.description.city }}
    <mat-icon svgIcon="pin"></mat-icon>
  </div>

  <figure *ngIf="selectedPlace.photo.src" class="place-displayer__picture" (click)="openFullScreenImg()">
    <mat-icon svgIcon="expand"></mat-icon>
    <img class="place-displayer__img" [src]="selectedPlace.photo.src" [alt]="selectedPlace.photo.alt" appNoRightClick/>
    <figcaption class="p-img-source">{{ selectedPlace.photo.source }}</figcaption>
  </figure>

  <div class="place-displayer__summary">
    <p [innerHTML]="selectedPlace.description.bio"></p>

    <a
      [href]="selectedPlace.link"
      target="_blank">Consulter la fiche complète de ce nom de lieu</a>
  </div>
</div>
