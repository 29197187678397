import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Media, MediaService } from '@igo2/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable } from 'rxjs';

import { Place, MapContext } from '../../portal.interface';
import { PortalService } from '../../portal.service';

@Component({
  selector: 'app-highligthed-places-slider',
  templateUrl: './highligthed-places-slider.component.html',
  styleUrls: ['./highligthed-places-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HighligthedPlacesSliderComponent implements OnChanges {

  public swiperConfig: SwiperConfigInterface = {
    autoHeight: true, //enable auto height
    spaceBetween: 20,
    direction: 'horizontal',
    slidesPerView: 1,
    // navigation: true,
    pagination: true,
    navigation: {
      nextEl: '.sidebar-swiper-button-next',
      prevEl: '.sidebar-swiper-button-prev',
    },
  }

  public index: number = 0;
  public upToDateSlider: boolean = true;

  @Input()
  public highlightedPlacesList: Place[];
  public mapContext$: Observable<MapContext> = this.portalService.getMapContext();

  constructor(
    public cdRef: ChangeDetectorRef,
    public portalService: PortalService,
    public mediaService: MediaService,
   ) {
  }

  public ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const chng = changes[propName];
      if(propName === 'highlightedPlacesList') {
        const cur  = JSON.stringify(chng.currentValue);
        const prev = JSON.stringify(chng.previousValue);
        if (cur !== prev) {
          this.upToDateSlider = false;
          console.log('highlightedPlacesList: ', this.highlightedPlacesList);
          this.index = 0;
          setTimeout(() => {
            this.upToDateSlider = true;
            this.cdRef.detectChanges();
          }, 0); //todo find a cleaner way
        }
      }
    }
  }

  public trackByFn(index: number, item: Place): string {
    return `${index}-${item.name}`;
  }

  public openFullScreenImg(): void {
    console.log('will full screen: ', this.highlightedPlacesList[this.index].photo.src);
    this.portalService.setPlaceToShowFullScreen(this.highlightedPlacesList[this.index]);
    this.portalService.setShowImageDisplayer(true);

  }

  isMobile(): boolean {
    return this.mediaService.getMedia() === Media.Mobile;
  }

  public jouerPrononciation(urlAudio: string):void {
    new Audio(urlAudio).play();
  }

}
