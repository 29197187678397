import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Place } from '../pages/portal/portal.interface';
import { PortalService } from '../pages/portal/portal.service';

@Component({
  selector: 'app-full-screen-image-displayer',
  templateUrl: './full-screen-image-displayer.component.html',
  styleUrls: ['./full-screen-image-displayer.component.scss']
})
export class FullScreenImageDisplayerComponent implements OnInit {

  public showImageDisplayer$: Observable<boolean> = this.portalService.getShowImageDisplayer();
  public PlaceToShowFullScreen$: Observable<Place> = this.portalService.getPlaceToShowFullScreen();

  @HostListener('document:keydown.escape', ['$event'])
  public onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
    this.closeImageDisplayer();
    event.stopPropagation();
  }

  constructor(private portalService: PortalService,
    ) { }

  public ngOnInit(): void {
  }

  public closeImageDisplayer(): void {
    this.portalService.setShowImageDisplayer(false);
  }

}
