import { NgModule } from '@angular/core';

import { MultiLineEllipsisPipe } from './multiLineEllipsis.pipe';
import { SafePipe } from './safe.pipe';
import { NoRightClickDirective } from './no-right-click.directive';

@NgModule({
    imports: [],
    declarations: [MultiLineEllipsisPipe, SafePipe, NoRightClickDirective],
    exports: [MultiLineEllipsisPipe, SafePipe,NoRightClickDirective],
})
export class HelpersModule { }
