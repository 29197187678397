<div
  [ngClass]="{'app-sidenav__min-opened': (getOpenSidePanelState$ | async) === 'min', 'app-sidenav__mid-opened': (getOpenSidePanelState$ | async) === 'mid'}">
  <mat-sidenav mode="side" [autoFocus]="false" [(opened)]="opened" [ngClass]="{'app-sidenav--darker-shadow': (isSatLayerActive$ | async)}">
    <div class="app-sidenav-content">
      <ng-container *ngIf="(getOpenSidePanelState$ | async) !== 'min'; else minOpenedContent">
        <div class="app-sidenav-content__header"
          [ngClass]="{'app-sidenav-content__header--commemorative' : (getSelectedPlace$ | async) }">
          <ng-container *ngIf="currentSelected; else noCelebritySelectedHeader">
            <ng-container *ngIf="(getSelectedPlace$ | async); else noSelectedPlaceHeader">
              <div class="app-sidenav-content__selected-place-header">
                <div class="app-sidenav-content__selected-place-header-top">
                  <ng-container *ngIf="isMobile(); else displayChevron">
                    <button mat-icon-button (click)="unselectPlace()">
                      <mat-icon svgIcon="arrow-back"></mat-icon>
                      <span>{{ currentSelected.name.fullName }}</span>
                    </button>
                  </ng-container>
                  <ng-template #displayChevron>
                    <b>{{ currentSelected.name.fullName }}</b>
                    <button mat-icon-button (click)="unselectPlace()">
                      <span>{{ ((mapContext$ | async) + '.bio-btn') | translate }}</span>
                      <mat-icon svgIcon="chevron-down"></mat-icon>
                    </button>
                  </ng-template>
                </div>

                <ng-container *ngIf="isMobile() && (getOpenSidePanelState$ | async) === 'mid'; else displayClose">
                  <button class="app-sidenav-content__header-up" (click)="changeOpenSidePanelStateToFull()"
                    mat-icon-button>
                    <mat-icon svgIcon="chevron-up"></mat-icon>
                  </button>
                </ng-container>
                <ng-template #displayClose>
                  <button class="app-sidenav-content__header-close" (click)="closeSideNav()" mat-icon-button>
                    <mat-icon svgIcon="close"></mat-icon>
                  </button>
                </ng-template>
              </div>

              <div class="app-sidenav-content__header-navigation">
                <button mat-icon-button (click)="selectPreviousCelebrityPlace()">
                  <mat-icon svgIcon="arrow-back"></mat-icon>
                  {{ 'global.prev' | translate }}
                </button>

                <button mat-icon-button (click)="selectNextCelebrityPlace()">
                  {{ 'global.next' | translate }}
                  <mat-icon svgIcon="arrow-forward"></mat-icon>
                </button>
              </div>
            </ng-container>

            <ng-template #noSelectedPlaceHeader>
              <div class="app-sidenav-content__header-navigation">
                <button mat-icon-button (click)="selectPreviousCelebrity()">
                  <mat-icon svgIcon="arrow-back"></mat-icon>
                  {{ 'global.prev' | translate }}
                </button>

                <button mat-icon-button (click)="selectNextCelebrity()">
                  {{ 'global.next' | translate }}
                  <mat-icon svgIcon="arrow-forward"></mat-icon>
                </button>
              </div>

              <button class="app-sidenav-content__header-close" (click)="closeSideNav()" mat-icon-button>
                <mat-icon svgIcon="close"></mat-icon>
              </button>
            </ng-template>
          </ng-container>

          <ng-template #noCelebritySelectedHeader>
            <div class="app-sidenav-content__header-navigation">
              <button mat-icon-button routerLink="/">
                <mat-icon svgIcon="map"></mat-icon>
                {{ 'global.map-menu' | translate }}
              </button>
            </div>
            <button class="app-sidenav-content__header-close" (click)="closeSideNav()" mat-icon-button>
              <mat-icon svgIcon="close"></mat-icon>
            </button>
          </ng-template>
        </div>

        <div *ngIf="currentSelected; else noCelebritySelected" class="sidenav__body">
          <div *ngIf="(getSelectedPlace$ | async) as selectedPlace; else noSelectedPlace">
            <app-place-displayer [selectedPlace]="selectedPlace"></app-place-displayer>
          </div>

          <ng-template #noSelectedPlace>
            <app-description [celebrity]="currentSelected"></app-description>
            <app-highligthed-places-slider *ngIf="currentSelected.highlightedPlacesIdsList.length > 0"
              [highlightedPlacesList]="getFilteredPlaces(currentSelected.highlightedPlacesIdsList)">
            </app-highligthed-places-slider>
            <app-commemorative-places
              [commemorativePlacesList]="getFilteredPlaces(currentSelected.commemorativePlacesIdsList)">
            </app-commemorative-places>
          </ng-template>
        </div>

        <ng-template #noCelebritySelected>
          <div  *ngIf="(mapContext$ | async) as mapContext" class="no-celebrity">
            <h1 class="no-celebrity__title">{{ (mapContext + '.title') | translate }}</h1>
            <div [innerHtml]=" (mapContext + '.description') | translate | safe : 'html'"></div>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #minOpenedContent>
        <button mat-icon-button class="app-sidenav-content__celebrity-button"
          (click)="changeOpenSidePanelStateToFull()">
          <h1>{{currentSelected.name.fullName}}</h1>
          <mat-icon svgIcon="plus"></mat-icon>
        </button>
      </ng-template>

    </div>
  </mat-sidenav>
</div>
